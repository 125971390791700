import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {Container, Row, Col} from "react-bootstrap"

const design3dPage = () => (
  <Layout>
 <SEO
        title="3D Design"
        keywords={[
          "tags",
          "Pramri Interiors tages",
          "false ceiling",
          "Tips for false ceiling",
          "Interior Design",
          "Interior Design planning",
          "Gypsum vs POP",
          "Different types of False Ceiling",
          "Modular Kitchen",
          "Kitchen Design",
          "House Painting",
          "Kitchen Laminates",
          "PVC Laminates",
          "Laminates Selection",
          "Small house Design",
          "Small master bedroom design",
          "Master Bedroom design",
          "Woodwork",
          "Home Interiors",
          "tags",
          "Pramri Interiors tags",
          "false ceiling in hindi",
          "Tips for false ceiling in hindi",
          "Interior Design in hindi",
          "Interior Design planning in hindi",
          "Gypsum vs POP in hindi ",
          "Different types of False Ceiling in hindi",
          "Modular Kitchen in hindi",
          "Kitchen Design in hindi ",
          "House Painting in hindi",
          "Kitchen Laminates in hindi",
          "PVC Laminates in hindi",
          "Laminates Selection in hindi",
          "Small house Design in hindi",
          "Small master bedroom design in hindi",
          "Master Bedroom design in hindi",
          "Woodwork in hindi",
          "Home Interiors in hindi" 
        ]}
      />
  <Container>
    <h1>Video Link</h1>
    <Row>
      <Col lg={6}>
       Hi Test
      </Col>
      <Col lg={6}>
        <p><strong>Youtube Video</strong></p>
        <div className="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/srmC-fbVPQo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
      </Col>
    </Row>
  </Container>
  
</Layout>
)

export default design3dPage

